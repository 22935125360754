import { I18nPluralPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { finalize, Subject, takeUntil, takeWhile, tap, timer } from 'rxjs';

@Component({
    selector: 'auth-inactivity-redirect',
    templateUrl: './inactivity-redirect.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, RouterLink, I18nPluralPipe],
})
export class AuthInactivityRedirectComponent implements OnInit, OnDestroy {
    countdown: number = 10;
    notRedirect = false;
    countdownMapping: any = {
        '=1': '# segundo',
        other: '# segundos',
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _router: Router, public dialogRef: MatDialogRef<AuthInactivityRedirectComponent>) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Redirect after the countdown
        timer(1000, 1000)
            .pipe(
                finalize(() => {
                    if (!this.notRedirect) {
                        this._router.navigate(['home']);
                        this.close();
                    }
                }),
                takeWhile(() => this.countdown > 0),
                takeUntil(this._unsubscribeAll),
                tap(() => this.countdown--),
            )
            .subscribe();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    close(): void {
        this.notRedirect = true;
        this.dialogRef.close(false);
    }
}
