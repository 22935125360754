import { CommonModule, CurrencyPipe, NgIf } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { ButtonFilterComponent } from 'app/shared/components/button-filter/button-filter.component';
import { NgxMaskPipe } from 'ngx-mask';
import { MatBadgeModule } from '@angular/material/badge';
import { AuthService } from 'app/core/auth/auth.service';
import { ErrorImageDirective } from 'app/shared/directives/erro-image.directive';
import { MatMenuModule } from '@angular/material/menu';
import { SettingsComponent } from 'app/layout/common/settings/settings.component';
import { MaterialLayoutComponent } from 'app/layout/layouts/horizontal/material/material.component';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { EnterpriseLayoutComponent } from 'app/layout/layouts/horizontal/enterprise/enterprise.component';
import { ClassicLayoutComponent } from 'app/layout/layouts/vertical/classic/classic.component';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';
import { CompactLayoutComponent } from 'app/layout/layouts/vertical/compact/compact.component';
import { DenseLayoutComponent } from 'app/layout/layouts/vertical/dense/dense.component';
import { CenteredLayoutComponent } from 'app/layout/layouts/horizontal/centered/centered.component';
import { ModernLayoutComponent } from 'app/layout/layouts/horizontal/modern/modern.component';
import { FuturisticLayoutComponent } from 'app/layout/layouts/vertical/futuristic/futuristic.component';
import { ThinLayoutComponent } from 'app/layout/layouts/vertical/thin/thin.component';
import { DevstroupeLoadingBarComponent } from '@devstroupe/components/loading-bar';
import { CompanyService } from 'app/modules/pec/company/company.service';
import { CompanyModel } from 'app/modules/pec/company/company.model';
import { EcommerceService } from 'app/modules/pec/ecommerce/ecommerce.service';
@Component({
    selector: 'layout-ecommerce',
    templateUrl: './layout-ecommerce.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,

    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatBadgeModule,
        MatMenuModule,
        DevstroupeLoadingBarComponent,
        RouterModule,
    ],
})
export class LayoutEcommerceComponent {
    currencyCart = 0;
    countItems = 0;
    company: CompanyModel;

    constructor(private authService: AuthService, private router: Router, private ecommerceService: EcommerceService, private companyService: CompanyService) {
        this.ecommerceService.currentCompanyCart$.subscribe((cart) => {
            this.currencyCart = cart?.items?.map((item) => +item.product_company.price * +item.quantity, 0).reduce((a, b) => a + b, 0);
            this.countItems = cart?.items.length;
        });

        CompanyService.company$.subscribe((company) => {
            this.company = company;
        });
    }

    ngOnInit(): void {}

    logout() {
        this.authService.logoutNotReload();
        this.router.navigate(['/sign-in']);
    }

    goToRelatorios() {
        this.router.navigate(['pec/relatorios']);
    }
    goToPedidos() {
        this.router.navigate(['pec/pedidos']);
    }

    goToCart() {
        this.router.navigate([this.company.route_ecommerce, 'cart']);
    }
}
