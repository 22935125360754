import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserModel } from 'app/modules/home/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { of, switchMap, take } from 'rxjs';

export const UserTypeGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);
    const requiredUserType = route.data['type'];

    if (!requiredUserType) return of(true);

    return ApiService.userCurrent.pipe(
        take(1),
        switchMap((user: UserModel) => {
            if (requiredUserType.includes(user.type)) {
                return of(true);
            }

            return of(router.parseUrl(''));
        }),
    );
};
