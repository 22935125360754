import { Injectable } from '@angular/core';
import { DevstroupeNavigationItem } from '@devstroupe/components/navigation';
import { DevstroupeMockApiService } from '@devstroupe/lib/mock-api';
import {
    compactNavigation,
    futuristicNavigation,
    horizontalNavigation,
    PECNavigation,
    PCPNavigation,
    BINavigation,
    TIPNavigation,
} from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
    private readonly _compactNavigation: DevstroupeNavigationItem[] = compactNavigation;
    private readonly _PCPNavigation: DevstroupeNavigationItem[] = PCPNavigation;
    private readonly _PECNavigation: DevstroupeNavigationItem[] = PECNavigation;
    private readonly _BINavigation: DevstroupeNavigationItem[] = BINavigation;
    private readonly _TIPNavigation: DevstroupeNavigationItem[] = TIPNavigation;
    private readonly _futuristicNavigation: DevstroupeNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: DevstroupeNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(private _devstroupeMockApiService: DevstroupeMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._devstroupeMockApiService.onGet('api/common/navigation').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._PCPNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._PCPNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._PCPNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact: cloneDeep(this._compactNavigation),
                    PCP: cloneDeep(this._PCPNavigation),
                    PEC: cloneDeep(this._PECNavigation),
                    BI: cloneDeep(this._BINavigation),
                    TIP: cloneDeep(this._TIPNavigation),
                    futuristic: cloneDeep(this._futuristicNavigation),
                    horizontal: cloneDeep(this._horizontalNavigation),
                },
            ];
        });
    }
}
