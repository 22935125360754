import { ProductCompanyService } from './../product-company/product-company.service';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, switchMap, take, tap } from 'rxjs';
import { ApiService } from 'app/shared/apis/api.service';
import { ItemOrderProductUserModel } from './product-company.model';
import { CompanyService } from '../company/company.service';
import { CompanyModel } from '../company/company.model';
import { ProductCompanyModel } from '../product-company/product-company.model';

export class Cart {
    items: ItemOrderProductUserModel[];
    company_id: number;
}

@Injectable({
    providedIn: 'root',
})
export class EcommerceService {
    private _carts = new BehaviorSubject<Cart[]>([]);
    company: CompanyModel;

    constructor(private productCompanyService: ProductCompanyService, private _matDialog: MatDialog) {
        CompanyService.company$.subscribe((company) => {
            this.company = company;
            if (company?.has_ecommerce && company?.route_ecommerce) {
                this.loadCart(company.company_id);
            }
        });
    }

    get carts$(): Observable<Cart[]> {
        return this._carts.asObservable();
    }

    get currentCompanyCart$(): Observable<Cart> {
        return this._carts.pipe(map((carts) => carts.find((c) => c.company_id === this.company.company_id)));
    }

    loadCart(company_id) {
        const cartSerialized = this.cartSerialized();
        this._carts.next(cartSerialized);

        const cartCompany = cartSerialized.find((cart) => cart.company_id === company_id);
        if (cartCompany) {
            this.verifyCart(cartCompany);
        } else {
            const newCart: Cart = {
                items: [],
                company_id,
            };
            this._carts.next([...this._carts.value, newCart]);
        }
    }

    verifyCart(cart: Cart) {
        if (cart) {
            for (const item of cart.items) {
                this.productCompanyService.getById(item.product_company_id).subscribe((product) => {
                    item.product_company = product;
                    item.value = product.price;
                });
            }
            cart.items.forEach((item) => {
                if (item.quantity > item.product_company.stock && item.product_company.has_stock) {
                    this.addToCart(item);
                }
            });
        }
    }

    cartSerialized() {
        const cartCompany = localStorage.getItem('cartCompany');

        if (cartCompany) {
            return JSON.parse(cartCompany);
        }
        return [];
    }

    saveCart() {
        const cart = this._carts.value;
        localStorage.setItem('cartCompany', JSON.stringify(cart));
    }

    addToCart(orderProduct: ItemOrderProductUserModel) {
        const cart = this._carts.value.find((c) => c.company_id === this.company.company_id);
        const item = cart.items.find((item) => item.product_company_id === orderProduct.product_company_id);
        if (item) {
            item.quantity += orderProduct.quantity;
            item.value = orderProduct.product_company.price;
        } else {
            orderProduct.value = orderProduct.product_company.price;
            cart.items.push(orderProduct);
        }

        if (orderProduct.quantity > orderProduct.product_company.stock && orderProduct.product_company.has_stock) {
            return;
        }

        this._carts.next([...this._carts.value]);

        this.saveCart();
    }

    removeFromCart(orderProduct: ItemOrderProductUserModel) {
        this._carts.next(
            this._carts.value.map((c) =>
                c.company_id === this.company.company_id
                    ? { ...c, items: c.items.filter((item) => item.product_company_id !== orderProduct.product_company_id) }
                    : c,
            ),
        );

        this.saveCart();
    }

    addQuantity(orderProduct: ItemOrderProductUserModel) {
        const item = this._carts.value
            .find((c) => c.company_id === this.company.company_id)
            .items.find((item) => item.product_company_id === orderProduct.product_company_id);
        if (item) {
            item.quantity += 1;
        }

        if (item.quantity > item.product_company.stock && item.product_company.has_stock) {
            return;
        }

        this._carts.next([...this._carts.value.map((c) => (c.company_id === this.company.company_id ? { ...c, items: [...c.items] } : c))]);
        this.saveCart();
    }

    removeQuantity(product: ItemOrderProductUserModel) {
        const item = this._carts.value
            .find((c) => c.company_id === this.company.company_id)
            .items.find((item) => item.product_company_id === product.product_company_id);
        if (item) {
            item.quantity -= 1;
        }

        this._carts.next([...this._carts.value.map((c) => (c.company_id === this.company.company_id ? { ...c, items: [...c.items] } : c))]);
        this.saveCart();
    }

    calculatedValue() {
        const cart = this._carts.value.find((c) => c.company_id === this.company.company_id);
        if (!cart) {
            return;
        }
        return cart.items.map((item) => +item.product_company.price * item.quantity).reduce((a, b) => a + b, 0);
    }

    getProductCart(product: ProductCompanyModel) {
        const cart = this._carts.value.find((c) => c.company_id === product.company_id);

        if (!cart) {
            return;
        }
        return cart.items.find((item) => item.product_company_id === product.product_company_id);
    }

    cleanCart() {
        this._carts.next(this._carts.value.map((c) => (c.company_id === this.company.company_id ? { ...c, items: [] } : c)));
        this.saveCart();
    }
}
