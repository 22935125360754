import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { devstroupeAnimations } from '@devstroupe/animations';
import { DevstroupeNavigationService } from '@devstroupe/components/navigation/navigation.service';
import { DevstroupeNavigationItem } from '@devstroupe/components/navigation/navigation.types';
import { DevstroupeUtilsService } from '@devstroupe/services/utils/utils.service';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { DevstroupeHorizontalNavigationBasicItemComponent } from './components/basic/basic.component';
import { DevstroupeHorizontalNavigationBranchItemComponent } from './components/branch/branch.component';
import { DevstroupeHorizontalNavigationSpacerItemComponent } from './components/spacer/spacer.component';
import { ApiService } from 'app/shared/apis/api.service';
import { UserModel } from 'app/modules/home/user/user.model';
import { HiddenOptions } from 'app/mock-api/common/navigation/data';

@Component({
    selector: 'devstroupe-horizontal-navigation',
    templateUrl: './horizontal.component.html',
    styleUrls: ['./horizontal.component.scss'],
    animations: devstroupeAnimations,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'devstroupeHorizontalNavigation',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        DevstroupeHorizontalNavigationBasicItemComponent,
        DevstroupeHorizontalNavigationBranchItemComponent,
        DevstroupeHorizontalNavigationSpacerItemComponent,
    ],
})
export class DevstroupeHorizontalNavigationComponent implements OnChanges, OnInit, OnDestroy {
    @Input() name: string = this._devstroupeUtilsService.randomId();
    @Input() navigation: DevstroupeNavigationItem[];

    onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _devstroupeNavigationService: DevstroupeNavigationService,
        private _devstroupeUtilsService: DevstroupeUtilsService,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Navigation
        if ('navigation' in changes) {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }
    hiddenOptions: HiddenOptions = {
        user: null,
    };
    /**
     * On init
     */
    ngOnInit(): void {
        ApiService.userCurrent.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: UserModel) => {
            if (user) {
                this.hiddenOptions = { user: user };
            }
            // Mark for check
            // this._changeDetectorRef.markForCheck();
        });
        // Make sure the name input is not an empty string
        if (this.name === '') {
            this.name = this._devstroupeUtilsService.randomId();
        }

        // Register the navigation component
        this._devstroupeNavigationService.registerComponent(this.name, this);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Deregister the navigation component from the registry
        this._devstroupeNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
