<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:shadow sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div class="flex justify-center">
                <img class="w-60" src="assets/images/logo/trevo-logo.png" />
            </div>

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight text-center">Você ficou inativo!</div>
            <div class="flex justify-center mt-0.5 font-medium">
                <!-- Redirect countdown -->
                <ng-container *ngIf="countdown > 0"> Redirecionando em {{ countdown | i18nPlural : countdownMapping }} </ng-container>

                <!-- Redirect message -->
                <ng-container *ngIf="countdown === 0"> Você está sendo redirecionado! </ng-container>
            </div>

            <!-- Form footer -->
            <div class="mt-8 text-md font-medium text-secondary text-center">
                <button mat-stroked-button (click)="close()">Fechar</button>
            </div>
        </div>
    </div>
</div>
