import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { CanActivateFn } from '@angular/router';
import { InactivityService } from 'app/shared/services/inactivity.service';

export const InactivityGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);
    const inactivityService = inject(InactivityService);

    // Garantir que a inatividade seja monitorada
    inactivityService.resetInactivityTimer();

    return true;
};
