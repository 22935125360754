import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserModel } from 'app/modules/home/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { forEach } from 'lodash';
import { of, switchMap, take } from 'rxjs';

export const FunctionGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);
    let requiredFunctions: string[] = typeof route.data['functions'] === 'string' ? [route.data['functions']] : route.data['functions'];

    if (!requiredFunctions) return of(true);

    return ApiService.userCurrent.pipe(
        take(1),
        switchMap((user: UserModel) => {
            const hasRequiredFunctions = requiredFunctions.every((functionName) => user.function?.[functionName]);

            if (hasRequiredFunctions) {
                return of(true);
            }

            return of(router.parseUrl(''));
        }),
    );
};
