import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserModel } from 'app/modules/home/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { forEach } from 'lodash';
import { of, switchMap, take } from 'rxjs';

export const PecGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router = inject(Router);

    return ApiService.userCurrent.pipe(
        take(1),
        switchMap((user: UserModel) => {
            const hasCondition = user.function?.['pec_enabled'] || (user.type_user_customer && user.type == 'CLIENT');

            if (hasCondition) {
                return of(true);
            }

            return of(router.parseUrl(''));
        }),
    );
};
