<div class="flex items-center justify-center" [ngClass]="{ 'text-white': isHome }" *ngIf="user">
    <span class="font-medium mr-1 capitalize hidden sm:block">{{ user?.name }}</span>
    <!-- Button -->
    <button mat-icon-button [disabled]="!isHome" [matMenuTriggerFor]="userActions">
        <span class="relative flex items-center justify-center">
            <mat-icon
                class="icon-size-7"
                [ngClass]="{ 'text-white': isHome }"
                [svgIcon]="isHome ? 'heroicons_outline:cog-8-tooth' : 'heroicons_outline:user-circle'"
            ></mat-icon>
        </span>
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Acessando como</span>
                <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>

        <button *ngIf="isHome && user.function?.is_admin" mat-menu-item [routerLink]="['/home/funcoes']">
            <mat-icon [svgIcon]="'heroicons_outline:key'"></mat-icon>
            <span>Funções</span>
        </button>
        <button *ngIf="isHome && user.function?.is_admin" mat-menu-item [routerLink]="['/home/usuarios/user']">
            <mat-icon [svgIcon]="'heroicons_outline:users'"></mat-icon>
            <span>Usuários</span>
        </button>
        <button *ngIf="isHome && user.function?.is_admin" mat-menu-item [routerLink]="['/home/banners']">
            <mat-icon [svgIcon]="'heroicons_outline:globe-americas'"></mat-icon>
            <span>Banner</span>
        </button>

        <button mat-menu-item [routerLink]="['/change-password']">
            <mat-icon [svgIcon]="'heroicons_outline:shield-check'"></mat-icon>
            <span>Alterar senha</span>
        </button>

        <button *ngIf="isHome" mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
            <span>Sair</span>
        </button>
    </mat-menu>
</div>
