/* eslint-disable */
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DevstroupeNavigationItem } from '@devstroupe/components/navigation';
import { TypeUserCustomer, UserModel } from 'app/modules/home/user/user.model';

const IS_NOT_ADMIN = (item: HiddenOptions) => {
    return !item?.user || !item?.user?.function || item?.user?.function?.is_admin === false;
};

const IS_NOT_USER = (item: HiddenOptions) => {
    return item?.user?.type !== 'USER';
};

// criação de funções para ocultar os itens do menu sando a screens
export const HIDE_IF_SCREEN = (item: HiddenOptions, screen: string) => {
    return !item?.user;
};

//hide if function
export const HIDE_IF_FUNCTION = (item: HiddenOptions, function_name: string) => {
    return Boolean(item?.user?.function?.[function_name]);
};

export const HIDE_IF_TYPE = (item: HiddenOptions, type: 'USER' | 'CLIENT') => {
    return item?.user?.type !== type;
};
export const HIDE_IF_TYPE_CUSTOMER = (item: HiddenOptions, type: TypeUserCustomer) => {
    return item?.user?.type_user_customer !== type;
};
export interface HiddenOptions {
    user?: UserModel;
}

export const PCPNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'p.production_orders',
        title: 'Ordens de Produção',
        subtitle: '',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/pcp/ordens-producao',
    },
    {
        id: 'p.clients',
        title: 'Clientes',
        subtitle: '',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/pcp/usuarios/client',
        hidden: (item) => HIDE_IF_FUNCTION(item, 'has_view_only_our_investment'),
    },
    {
        id: 'p.cad',
        title: 'Cadastros',
        subtitle: '',
        hidden: (item) => IS_NOT_ADMIN(item),
        type: 'collapsable',
        icon: 'heroicons_outline:pencil-square',
        children: [
            {
                id: 'p.status',
                title: 'Status',
                type: 'basic',
                icon: 'heroicons_outline:information-circle',
                link: '/pcp/status',
            },
            {
                id: 'p.reasons-loss',
                title: 'Motivos de Perda',
                type: 'basic',
                icon: 'heroicons_outline:exclamation-triangle',
                link: '/pcp/motivos-perda',
            },
            {
                id: 'p.reasons-loss',
                title: 'Fundo de Investimento',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/pcp/fundo-investimento',
            },
            {
                id: 'p.cad.tipo_de_anexo',
                title: 'Tipo de Anexo',
                type: 'basic',
                icon: 'heroicons_outline:paper-clip',
                link: '/pcp/tipo_de_anexo',
            },
        ],
    },
    {
        id: 'p.PCP',
        title: 'Voltar a Home',
        subtitle: '',
        hidden: (item) => IS_NOT_USER(item),
        type: 'basic',
        link: '/home',
        icon: 'heroicons_outline:home',
    },
];
export const PECNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'p.pedidos',
        title: 'Pedidos',
        subtitle: '',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/pec/pedidos',
    },
    {
        id: 'pec.clients',
        title: 'Usuários',
        subtitle: '',
        type: 'basic',
        hidden: (item) => HIDE_IF_TYPE_CUSTOMER(item, 'matriz') && HIDE_IF_TYPE_CUSTOMER(item, 'gestor') && !IS_NOT_USER(item),

        icon: 'heroicons_outline:users',
        link: '/pec/usuarios/client',
    },
    {
        id: 'pec.clients',
        title: 'Clientes',
        subtitle: '',
        hidden: (item) => IS_NOT_USER(item),

        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/pec/usuarios/client',
    },
    {
        id: 'pec.relatorios',
        title: 'Relatórios',
        subtitle: '',
        hidden: (item) => !IS_NOT_USER(item),

        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/pec/relatorios',
    },
    {
        id: 'p.cad',
        title: 'Cadastros',
        subtitle: '',
        hidden: (item) => IS_NOT_ADMIN(item),
        type: 'collapsable',
        icon: 'heroicons_outline:pencil-square',
        children: [
            {
                id: 'p.cad.company',
                title: 'Empresas',
                type: 'basic',
                icon: 'heroicons_outline:building-office',
                link: '/pec/empresas',
            },
            {
                id: 'p.cad.company.produto',
                title: 'Produtos',
                type: 'basic',
                icon: 'heroicons_outline:building-office',
                link: '/pec/produtos',
            },
        ],
    },
    {
        id: 'p.PCP',
        title: 'Voltar a Home',
        subtitle: '',
        type: 'basic',
        link: '/home',
        icon: 'heroicons_outline:home',
    },
    {
        id: 'p.PEC',
        title: 'Ir para a LOJA',
        subtitle: '',
        hidden: (item) => !IS_NOT_USER(item),
        type: 'basic',
        link: '/loja-redirect',
    },
];

export const TIPNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'p.help',
        title: 'Informações',
        subtitle: '',
        type: 'basic',
        link: '/tip/ajuda',
        queryParams: { group: 'informations' },
        // icon: 'heroicons_outline:question-mark-circle',
    },
    {
        id: 'p.help',
        title: 'Processos',
        subtitle: '',
        type: 'basic',
        link: '/tip/ajuda',
        queryParams: { group: 'processes' },
        // icon: 'heroicons_outline:question-mark-circle',
    },
    {
        id: 'p.cad',
        title: 'Cadastros',
        subtitle: '',
        hidden: (item) => IS_NOT_ADMIN(item),
        type: 'collapsable',
        icon: 'heroicons_outline:pencil-square',
        children: [
            {
                id: 'p.topic',
                title: 'Tópicos',
                subtitle: '',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/tip/topic',
            },
            {
                id: 'p.category',
                title: 'Categorias',
                subtitle: '',
                type: 'basic',
                link: '/tip/category',
                icon: 'heroicons_outline:queue-list',
            },
        ],
    },
    {
        id: 'p.PCP',
        title: 'Voltar a Home',
        subtitle: '',
        type: 'basic',
        link: '/home',
        icon: 'heroicons_outline:home',
    },
];

export const BINavigation: DevstroupeNavigationItem[] = [
    {
        id: 'p.relatorio',
        title: 'Relatorio',
        subtitle: '',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/bi/relatorios',
    },
    {
        id: 'p.PCP',
        title: 'Voltar a Home',
        subtitle: '',
        type: 'basic',
        link: '/home',
        icon: 'heroicons_outline:home',
    },
];

export const compactNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: DevstroupeNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
