import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthInactivityRedirectComponent } from 'app/modules/auth/inactivity-redirect/inactivity-redirect.component';

@Injectable({
    providedIn: 'root',
})
export class InactivityService implements OnDestroy {
    private inactivityTimeout: any;
    // private readonly INACTIVITY_TIME = 30000; // 30 segundos em milissegundos
    private readonly INACTIVITY_TIME = 900000; // 15 minutos em milissegundos

    constructor(private router: Router, private dialog: MatDialog) {
        this.resetInactivityTimer();

        window.addEventListener('mousemove', (event: Event) => this.onUserActivity(event));
        window.addEventListener('keydown', (event: Event) => this.onUserActivity(event));
        window.addEventListener('click', (event: Event) => this.onUserActivity(event));
    }

    onUserActivity(event: Event) {
        this.resetInactivityTimer();
    }

    resetInactivityTimer() {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            if (!this.router.url.includes('/home')) {
                this.dialog.open(AuthInactivityRedirectComponent, {
                    panelClass: 'card-dialog',
                    // height: '700px',
                    // maxHeight: '90vh',
                    // width: '1200px',
                    // maxWidth: '90vw',
                });
            }
        }, this.INACTIVITY_TIME);
    }

    ngOnDestroy() {
        clearTimeout(this.inactivityTimeout);
        window.removeEventListener('mousemove', this.onUserActivity);
        window.removeEventListener('keydown', this.onUserActivity);
        window.removeEventListener('click', this.onUserActivity);
    }
}
