import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { devstroupeAnimations } from '@devstroupe/animations';
import { DevstroupeFullscreenComponent } from '@devstroupe/components/fullscreen';
import { DevstroupeLoadingBarComponent } from '@devstroupe/components/loading-bar';
import { DevstroupeNavigationItem, DevstroupeNavigationService, DevstroupeVerticalNavigationComponent } from '@devstroupe/components/navigation';
import { DevstroupeMediaWatcherService } from '@devstroupe/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserComponent } from 'app/layout/common/user/user.component';
import { UserModel } from 'app/modules/home/user/user.model';
import { ApiService } from 'app/shared/apis/api.service';
import { debounceTime, filter, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'home-layout',
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    styles: [
        `
            home-layout {
                .devstroupe-vertical-navigation-content {
                    display: flex;
                    flex-direction: column;
                }
            }
        `,
    ],
    imports: [
        NgStyle,
        DevstroupeLoadingBarComponent,
        DevstroupeVerticalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        UserComponent,
        NgIf,
        RouterOutlet,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        RouterModule,
        NgFor,
        MatTooltipModule,
    ],
    animations: devstroupeAnimations,
    // animations: [
    //     trigger('fadeInOut', [
    //         transition(':enter', [
    //             style({ opacity: 0 }), // Inicialmente invisível
    //             animate('100ms ease-in', style({ opacity: 1 })), // Transição para visível
    //         ]),
    //         transition(':leave', [
    //             animate('100ms ease-out', style({ opacity: 0 })), // Transição para invisível
    //         ]),
    //     ]),
    // ],
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    navigationData: DevstroupeNavigationItem[];
    hovered;
    config = {
        logo: 'assets/images/logo/trevo-logo-white.png',
        class: 'bg-primary-500',
    };

    erpImage: string = 'assets/images/logo/erp.png';
    erpHoverImage: string = 'assets/images/logo/erp-hover.png';

    pcpImage: string = 'assets/images/logo/pcp.png';
    pcpHoverImage: string = 'assets/images/logo/pcp-hover.png';

    tipImage: string = 'assets/images/logo/tip.png';
    tipHoverImage: string = 'assets/images/logo/tip-hover.png';

    pecImage: string = 'assets/images/logo/pec.png';
    pecHoverImage: string = 'assets/images/logo/pec-hover.png';

    biImage: string = 'assets/images/logo/bi.png';
    biHoverImage: string = 'assets/images/logo/bi-hover.png';
    user: UserModel;
    menuItems = [
        {
            id: 'erp',
            label: 'Planejamento de Recursos Empresariais',
            link: '/erp',
            image: this.erpImage,
            hoverColor: '#BABABA',
            textColor: 'text-white',
            validate: () => this.user.function?.['erp_enabled'],
            tooltipInvalid: 'Sem acesso ao ERP',
        },
        {
            id: 'pcp',
            label: 'Planejamento e Controle de Produção',
            link: '/pcp/ordens-producao',
            image: this.pcpImage,
            hoverColor: '#1B5A90',
            textColor: 'text-white',
            validate: () => this.user.function?.['pcp_enabled'],
            tooltipInvalid: 'Sem acesso ao PCP',
        },
        {
            id: 'tip',
            label: 'Treinamento, Informação e Processos',
            link: '/tip',
            image: this.tipImage,
            hoverColor: '#00A1B7',
            textColor: 'text-white',
            validate: () => this.user.function?.['tip_enabled'],
            tooltipInvalid: 'Sem acesso ao TI',
        },
        {
            id: 'pec',
            label: 'Portifolio Eletrônico de Cliente',
            link: '/pec/pedidos',
            image: this.pecImage,
            hoverColor: '#F3C249',
            textColor: 'text-dark',
            validate: () => this.user.function?.['pec_enabled'] || (this.user.type_user_customer && this.user.type == 'CLIENT'),
            tooltipInvalid: 'Sem acesso ao PEC',
        },
        {
            id: 'bi',
            label: 'Business Intelligence',
            link: '/bi',
            image: this.biImage,
            hoverColor: '#4BBF82',
            textColor: 'text-white',
            validate: () => this.user.function?.['bi_enabled'],
            tooltipInvalid: 'Sem acesso ao BI',
        },
    ];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _devstroupeMediaWatcherService: DevstroupeMediaWatcherService,
        private _devstroupeNavigationService: DevstroupeNavigationService,
    ) {}
    currentDateTime: Date = new Date();
    private intervalId: any;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    searchEnabled: boolean = true;
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        ApiService.userCurrent.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: UserModel) => {
            this.user = user;
        });
        // Subscribe to navigation data
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            this.navigation = navigation;
            this.setNavigationData();
        });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((event: NavigationEnd) => {
                if (event.url == '/' || event.url == '/home') {
                    this.searchEnabled = false;
                } else {
                    this.searchEnabled = true;
                }
            });

        this._activatedRoute.data.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ config }) => {
            if (config) {
                this.config = config;
            }
        });

        this.searchInputControl.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(350),
                switchMap((query) => {
                    this._router.navigate([], {
                        relativeTo: this._activatedRoute,
                        queryParams: {
                            text: query,
                        },
                        queryParamsHandling: 'merge',
                    });
                    return query;
                }),
            )
            .subscribe();

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((event) => {
                if (!this._activatedRoute.snapshot.queryParams['text']) {
                    this.searchInputControl.setValue('', { emitEvent: false });
                }
            });
        // Subscribe to media changes
        this._devstroupeMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
        });
        this.updateDateTime();
        this.intervalId = setInterval(() => this.updateDateTime(), 1000);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        clearInterval(this.intervalId);
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private updateDateTime(): void {
        const now = new Date();
        // const options: Intl.DateTimeFormatOptions = {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        //     hour: '2-digit',
        //     minute: '2-digit',
        //     second: '2-digit',
        //     hour12: false,
        // };
        this.currentDateTime = now;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._devstroupeNavigationService.getComponent<DevstroupeVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    setNavigationData(): void {
        this.navigationData = [];
    }
}
